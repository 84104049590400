import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["downloadButton"]

  handleImportTypeChange(event) {
    const isImmediateUpdateSelected = event.target.value === "ImmediateServicePriceUpdate"
    this.downloadButtonTarget.classList.toggle("hidden", !isImmediateUpdateSelected)
  }
}
